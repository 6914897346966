import { FC } from 'react';

import RichText from '@components/RichText/RichText';
import { Checklist as ChecklistComponent } from '@components/ui/Checklist/Checklist';
import { ChecklistRendering } from '@sitecore/types/manual/Checklist';
import { Text } from '@sparky';

const Checklist: FC<ChecklistRendering> = ({ fields: { items }, params }) => {
  const gap = params?.isFilledCheckmark ? 5 : 1;
  const gapItem = params?.isFilledCheckmark ? 3 : 1;
  const icon = params?.isFilledCheckmark ? 'checkmark-filled' : undefined;
  const fontWeight = params?.isFilledCheckmark ? 'bold' : undefined;

  return (
    <ChecklistComponent gap={gap}>
      {items.map((item, index) => {
        return (
          <ChecklistComponent.Item key={index} icon={icon} gap={gapItem}>
            <Text size={{ initial: 'BodyM', md: 'BodyL' }} weight={fontWeight}>
              <RichText html={item} />
            </Text>
          </ChecklistComponent.Item>
        );
      })}
    </ChecklistComponent>
  );
};

export default Checklist;
